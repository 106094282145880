import { render, staticRenderFns } from "./Tablist.vue?vue&type=template&id=79e3ecf4"
import script from "./Tablist.vue?vue&type=script&lang=ts"
export * from "./Tablist.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports